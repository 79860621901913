import React,{ useState,useEffect } from 'react';
import DataProvider from '../../resources/data-provider';
import ServiceNavigation from '../ServiceNavigation.jsx';
import ExtendingPanel from '../ExtendingPanel.jsx';
import BrowserCheck from '../BrowserCheck';
import TopPanel from '../TopPanel';
import { Buffer } from "buffer";
import architectureImg from './images/EdgeConfigurator-Page-1.drawio.png';
import agentImg from './images/EdgeConfigurator-Page-2.drawio.png';
// import { socket } from './socket';
// import ReviewSpecsEditor from './reviewspecs';
import { io } from 'socket.io-client';
import {INSIGHTS_ANALYSIS_ENDPOINT} from '../../resources/prod-env.jsx';
        
import {
    AppLayout,
    Button,
    ColumnLayout,
    Form,
    Table,
    Header,
    SpaceBetween,
    Container,
    Link,
    HelpPanel,
    Box,
    Alert,
    Input,
    StatusIndicator,
    ExpandableSection,
    FormField,
    Select,
    CopyToClipboard,
    Grid,
    Textarea,
    TextContent,
    ProgressBar,
    Wizard,
    Checkbox,
    Icon,
    Badge,
    TokenGroup,
    PromptInput,
} from '@cloudscape-design/components';
import Avatar from "@cloudscape-design/chat-components/avatar";
import LoadingBar from "@cloudscape-design/chat-components/loading-bar";

import typescriptHighlight from "@cloudscape-design/code-view/highlight/typescript";
import {CodeView} from "@cloudscape-design/code-view";

import '../../styles/form.scss';
import SynopsisPanelV3 from '../SynopsisPanelV3';
import { sendAnalytics, sendError } from '../../resources/rum-provider';
import { Code } from 'react-feather';
// import {SPECS} from './specstemplate.jsx';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const demoModule = "TrendAnalysis:InsightsDeepDive";
const demoType = "tool";

// Class CreateForm is a skeleton of a Single page create form using AWS-UI React components.
export default class InsightsDeepDive extends React.Component {

    constructor(props) {
        super(props);
        // console.log(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleFetch = this.handleFetch.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleAlert = this.handleAlert.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        // this.onFollowQuestion = this.onFollowQuestion.bind(this);
        this.onConnect = this.onConnect.bind(this);
        this.onDisconnect = this.onDisconnect.bind(this);
        this.onError = this.onError.bind(this);
        // this.onQuestion = this.onQuestion.bind(this);
        this.dataProvider = new DataProvider();
        
        this.createPromise = this.createPromise.bind(this);
        this.resolver = null;
        this.addMessage = this.addMessage.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.addCompletion = this.addCompletion.bind(this);
        this.handleOptionSelection = this.handleOptionSelection.bind(this);
        this.handleOptionValueSelection = this.handleOptionValueSelection.bind(this);
        this.handleCallback = this.handleCallback.bind(this);
        this.handleFetchCallback1 = this.handleFetchCallback1.bind(this);
        this.handleRemoveToken = this.handleRemoveToken.bind(this);
        // this.addLogs = this.addLogs.bind(this);

        let user = props.user;

        this.state = {
            toolsIndex: 0, toolsOpen: false, alert: false,
            q:"",
            status: "takes about a minute",
            statusType: "info",
            contentTypes: [],
            k:{label: "20",value: "20" },
            lang:{label: "English",value: "en" },
            authKey:'',
            userId:'',
            messages:[],gmessages:[],vmessages:[],
            logs:[],glogs:[],
            firstTime:true,gFirstTime:true,vFirstTime:true,
            currentProgress:0,
            currentProgressMsg:'',
            userScrolled:false,
            clearonreq:true,
            stage:'specreq',
            options:
            [
                { label: "Date Range", value: "date_range" },
                { label: "Customer", value: "customer" },
                { label: "AWS Service", value: "service" },
                { label: "Category", value: "category" }
            ],
            searchTokens:[]
            // user:props.user,
        };

        // console.log(user);
        if(user){
            let key = user.keyPrefix+"."+user.username+".accessToken";
            // console.log(key);
            let authKey = user.storage[key];
            this.state.authKey = authKey;
            this.state.userId = user.storage['userId'];
            // console.log(authKey);
        }

        // console.log(this.state.authKey);

        this.socket = io(INSIGHTS_ANALYSIS_ENDPOINT,{
            transports:['websocket'],
            // transports: ['polling'],
            autoConnect:false,
            reconnection:false,
            extraHeaders:{'Authorization':this.state.authKey},
            query: {
                token: this.state.authKey
              }
        });

        this.socket.on('connect', this.onConnect);
        this.socket.on('disconnect', this.onDisconnect);
        this.socket.on('connect_error', err => this.onError(err));
        this.socket.on('connect_failed', err => this.onError(err));        

        this.socket.on('answer', (value, callback) => {
            // console.log(value);
            // console.log(callback);
            this.addMessage('bot','answer',`Ans:${value?.data}`);
            const [ promise, resolve ] = this.createPromise();
            this.resolver = { resolve };
            // console.log("Promise",promise);
            if(callback){
                return promise.then((value)=>{
                    this.setState({'currentProgressMsg':'thinking..'});
                    // console.log("Returning value ",value);
                    callback(value);
                });          
            }  
        });

        this.socket.on('question', (value, callback) => {
            // console.log(value);
            // console.log(callback);
            this.addMessage('bot','question',`Q:${value?.data}`);
            const [ promise, resolve ] = this.createPromise();
            this.resolver = { resolve };
            // console.log("Promise",promise);
            if(callback){
                return promise.then((value)=>{
                    this.setState({'currentProgressMsg':'thinking..'});
                    // console.log("Returning value ",value);
                    callback(value);
                });          
            }  
        });

        this.socket.on('status', (value, callback) => {
            this.setState({'currentProgressMsg':value});
        });

        this.socket.on('log', (value) => {
            // console.log(callback);
            this.addMessage('bot','log',value);
            // this.addLogs(value);
        });
        this.socket.on('error', (value) => {
            // console.log(callback);
            this.addMessage('bot','error',value);
            // this.addLogs(value);
        });
       
        this.socket.on('finish', (value) => {
            console.log("In finish");
            // this.addMessage('bot','specs',value);
            this.addCompletion({
                "messages":this.state['messages']
            });
        });

        this.state['connected'] = this.socket.connected;
    }

    handleAdd(e) {
        e.preventDefault();
        console.log("In handleAdd");
        let token=`${this.state.selectedOption.label}:${this.state.selectedOptionValue.label}`;
        let item = { 'label': token, 'dismissLabel': token,'id':this.state.selectedOptionValue.value};
        let tokens = this.state.searchTokens;
        console.log(tokens);
        tokens.push(item);
        this.setState({searchTokens:tokens});
    }

    handleRemoveToken(index){
        console.log("In handleRemoveToken",index);
        let tokens = this.state.searchTokens;
        let modifiedTokens=[...tokens.slice(0, index),...tokens.slice(index + 1)];
        console.log(modifiedTokens);
        this.setState({searchTokens:modifiedTokens});
    }

    handleCallback(message){
        let jsonObj=JSON.parse(message);
        console.log("In handleCallback",jsonObj);
        let k=Object.keys(jsonObj)[0];
        console.log(k);
        console.log(jsonObj[k]);
        this.setState({ selectedOptionValues: jsonObj[k] });
        console.log(this.state);
    }

    handleOptionSelection(e) {
        console.log("handleOptionSelection selected ", e.value);
        this.setState({ selectedOption: e });
        // if(e.value === "date_range"){
        this.socket.emit('query', {"data":e.value},this.handleCallback);
        // }
    }

    handleOptionValueSelection(e) {
        console.log("handleOptionValueSelection selected ", e);
        this.setState({ selectedOptionValue: e });

        let token=`${this.state.selectedOption.label}:${e.label}`;
        let item = { 'label': token, 'dismissLabel': token,'id':e.value};
        let tokens = this.state.searchTokens;
        console.log(tokens);
        tokens.push(item);
        this.setState({searchTokens:tokens});
        // if(e.value === "date_range"){
        //     this.socket.emit('query', {"data":e.value},function(message, error){
        //         // console.log(error);
        //         console.log(message);
        //     });
        // }
    }
    
    addCompletion(payload){
        payload["userId"] = localStorage.getItem("userId");
        this.socket.emit('completion', payload);
    }
    
    addMessage(user,type,msg){

        let messageID = '';
        if(this.state.stage === 'specreq')
            messageID = 'messages';

        let messages = this.state[messageID];
        messages.push({'user':user,'msg':msg,'type':type});
        // console.log("Setting messages %s,%j",messageID,messages);        
        // var arr = msg.match(/\Action:(.*)$/gm) || [""];
        // console.log(arr);
        // this.setState({[messageID]:messages,'currentProgressMsg':arr[0]});
    }

    createPromise = () => {
        let resolver;
        return [ new Promise(( resolve, reject ) => {
            resolver = resolve;
            this.setState({'currentProgressMsg':'awaiting answer'});
        }), resolver]
    }

    onError(err){
        console.log("Error while connecting");
        this.addMessage('bot','log','Access denied!');
        // console.log(err);
    }

    onConnect() {
        console.log("Connected");
        this.setState({ 'connected': true,'currentProgressMsg':'connected'});
    }
  
    onDisconnect() {
        console.log("Disconnected");
        this.setState({ 'connected': false,'currentProgressMsg':'disconnected'});
    }

    onInputChange(name, e) {
        e.preventDefault();
        let value = null;
        if(name === 'q'){
            value = e.detail.value;
        }
        else if(name === 'clearonreq'){
            value = e.detail.checked;
            console.log("Checked :",value);
        }
        else{
            value = e.detail.selectedOption;
        }
        this.setState({ [name]: value});
    }

    // onFollowQuestion(e){
    //     console.log("In onFollowQuestion ",e);
    // }

    handleFetchCallback1(message){
        console.log("In handleFetchCallback");
        // let jsonObj=JSON.parse(message);
        // console.log("In handleFetchCallback",jsonObj);
        // this.addMessage('user','question',JSON.stringify(this.state.searchTokens));
        this.addMessage('bot','log',`${message}`);
        this.setState({'currentProgressMsg':'completed'});

    //     let k=Object.keys(jsonObj)[0];
    //     console.log(k);
    //     console.log(jsonObj[k]);
    //     this.setState({ selectedOptionValues: jsonObj[k] });
    //     console.log(this.state);
    }

    handleFetch(e) {
        console.log("In handleFetch",e);
        e.preventDefault();
        console.log("this.state.clearonreq ",this.state.clearonreq);
        if(this.state.clearonreq){
            this.handleClear();
        }
        this.setState({'currentProgressMsg':'fetching..'});

        if(this.state.firstTime){
            sendAnalytics({ demo: demoModule });
        }

        this.socket.emit('fetch', {"data":this.state.searchTokens,"q":this.state.q,"u":this.state.userId},this.handleFetchCallback1);
        this.setState({'firstTime':false});
    }

    handleClick(e) {
        // console.log("In handleClick ",e);

        e.preventDefault();
        if (!this.state.currentAnswer) {
            // console.log("Region not selected");
            this.setState({ alert: true });
            return;
        }
        else {
            this.handleAlert();
        }

        let currentAnswer = this.state.currentAnswer;

        this.setState({
            status: "in progress, may take upto a minute..",
            statusType: "loading"
        });

        this.addMessage('user','answer',currentAnswer);
        
        this.resolver?.resolve(currentAnswer);
        // this.socket.on('start',emit('start', {"data":"Initialize"});)
        // note usage analytics is recorded only once per session
        if(this.state.firstTime){
            console.log("First time");
            this.socket.emit('advanced', {"data":currentAnswer});
            this.setState({'firstTime':false});
            sendAnalytics({ demo: demoModule });
        }

        this.setState({'currentAnswer':'','stage':'specreq'});
    }

    handleAlert() {
        // console.log("In handleAlert");
        this.setState({ alert: false });
    }

    handleReset() {
        console.log("In handleReset");
        this.socket.disconnect();
        this.socket.connect();
        this.setState({'gFirstTime':true,'firstTime':true,'searchTokens':[],'q':""});
    }

// export default () => {
//   const [items, setItems] = React.useState([
//     { label: "Item 1", dismissLabel: "Remove item 1" },
//     { label: "Item 2", dismissLabel: "Remove item 2" },
//     { label: "Item 3", dismissLabel: "Remove item 3" }
//   ]);
//   return (
//   );
// }
    handleClear() {
        console.log("In handleClear");
        this.setState({'gFirstTime':true,'firstTime':true,
        'messages':[],'gmessages':[],'vmessages':[],
        'specreq':'','template':'','vtemplate':''});
    }

    handleScroll(e) {
        // console.log('User scrolled!', e);
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        // console.log("Is bottom ",bottom);
        this.setState({'userScrolled':!bottom});
    }

    componentDidCatch(error, info) {
        console.log(error);
        sendError(error);
        // cwr('recordError', error);
    };

    componentDidMount() {
        // this.dataProvider.getData('images', response => this.setState({ images: response }));
        // this.dataProvider.listContentTypes({"Authorization":this.state.authKey}, response => this.setState({ contentTypes: response }));
        // this.dataProvider.listQuestions({"Authorization":this.state.authKey}, response => this.setState({ questions: response }));
        this.socket.connect();
        // this.socket.timeout(5000).emit('start', {"data":"Initialize"});
        // , (value) => {
        //     this.onQuestion(value);
        //   });
    }

    componentWillUnmount(){
        console.log("In componentWillUnmount ");
        let events = ['question','connect','disconnect','log','explain','summary','specs','template'];
        events.forEach(event => {
            this.socket.off(event);
        });

        // this.socket.off('question');
        // this.socket.off('connect');
        // this.socket.off('disconnect');
        // this.socket.off('log');
        // this.socket.off('explain');
        // this.socket.off('summary');
        // this.socket.off('specs');
        // this.socket.off('template');
    }

    componentDidUpdate() {
        // I was not using an li but may work to keep your div scrolled to the bottom as li's are getting pushed to the div
        if(!this.state.userScrolled){
            const objDiv = document.getElementById('messages');
            if(objDiv){
                objDiv.scrollTop = objDiv.scrollHeight;
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <TopPanel label={demoModule} />
                <AppLayout
                    navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
                    // breadcrumbs={<Breadcrumbs items={BreadcrumbsItems} />}
                    content={
                        <Content
                            // Changes the Help panel content when the user clicks an 'info' link
                            replaceToolsContent={index => this.setState({ toolsIndex: index, toolsOpen: true })}
                            handleClick={this.handleClick}
                            handleFetch={this.handleFetch}
                            handleAlert={this.handleAlert}
                            onInputChange={this.onInputChange}
                            onFollowQuestion={this.onFollowQuestion}
                            handleReset={this.handleReset}
                            handleAdd={this.handleAdd}
                            handleClear={this.handleClear}
                            currentState={this.state}
                            handleScroll={this.handleScroll}
                            handleOptionSelection={this.handleOptionSelection}
                            handleOptionValueSelection={this.handleOptionValueSelection}
                            handleRemoveToken={this.handleRemoveToken}
                        />
                    }
                    contentType="default"
                    tools={Tools[this.state.toolsIndex]}
                    onToolsChange={({ detail }) => this.setState({ toolsOpen: detail.open })}
                    toolsOpen={this.state.toolsOpen}
                    navigationOpen={this.state?.navigationState}
                    onNavigationChange={e => this.setState({ navigationState: e.detail.open })}
                />
            </React.Fragment>
        );
    }
};

const FilterTokens = props => {

    return (
        <React.Fragment>
            <TokenGroup
            onDismiss={({ detail: { itemIndex } }) => {props.handleRemoveToken(itemIndex)}}
            items={props.currentState.searchTokens}
            />
        </React.Fragment>
    )
}

const Content = props => {

    return (
        <React.Fragment>
            <BrowserCheck />
            <SpaceBetween direction='vertical' size='xxxs'>
                <Badge color='red'>This section is only available to select users.
                If you see an 'Access denied!' message below it means you do not have access to it.</Badge>
                <SynopsisPanelV3 label="Synopsis" demotype={demoType} architectureImgs={[]}>
                Tool to assist in 2x2 generation and trend analysis from Marshal + SFDC Insights.
                Details on the category definitions: <Link external href='https://quip-amazon.com/5nLAAuyjd1xz'>https://quip-amazon.com/5nLAAuyjd1xz</Link>
                </SynopsisPanelV3>   
                    <Container>
                    <form onSubmit={props.handleFetch}>
                <Form
                actions={
                    <SpaceBetween direction='horizontal' size='s'>
                    <Checkbox onChange={props.onInputChange.bind(this, 'clearonreq')} 
                            checked={props.currentState['clearonreq']}>clear on fetch</Checkbox>
                    <Button variant="primary">Fetch</Button>
                    </SpaceBetween>
                }>
                    <Grid
                        gridDefinition={[
                            { colspan: 4 }, { colspan: 6 },
                            { colspan: 12}
                        ]}
                    >
                <SpaceBetween direction='horizontal' size='s'>

                  <FormField label="Filters">
                    <Select
                      selectedOption={props.currentState.selectedOption}
                      onChange={({ detail }) =>
                        props.handleOptionSelection(detail.selectedOption)
                      }
                      options={props.currentState.options}
                      // filteringType="auto"
                      selectedAriaLabel="Selected"
                      filteringType="auto"
                    />
                    </FormField>

                    <FormField label="Filter Values">
                    <Select
                      selectedOption={props.currentState.selectedOptionValue}
                      onChange={({ detail }) =>
                        props.handleOptionValueSelection(detail.selectedOption)
                      }
                      options={props.currentState.selectedOptionValues}
                      // filteringType="auto"
                      selectedAriaLabel="Selected"
                      filteringType="auto"
                    />
                    </FormField>
                    </SpaceBetween>
                    <PromptInput minRows={3}
                        onChange={props.onInputChange.bind(this, 'q')} 
                        value={props.currentState.q}
                        ariaLabel="Default prompt input"
                        placeholder="Ask a question"
                        />
                    <FilterTokens currentState={props.currentState} handleRemoveToken={props.handleRemoveToken}/>
                    </Grid>
                    </Form>
                    </form>
                    </Container>
                <ChatPanel messages={props.currentState.messages} onInputChange={props.onInputChange} 
                    handleClick={props.handleClick} 
                    currentState={props.currentState} handleScroll={props.handleScroll}/>
            
            <ColumnLayout columns={2}>
            <SpaceBetween direction='horizontal' size='xxxs'>
                    <Button variant="link" onClick={props.handleReset}>Reset</Button>
                    <Button variant="link" onClick={props.handleClear}>Clear</Button>   
            </SpaceBetween>
            <Box
              margin={{ bottom: "xs", left: "l" }}
              color="text-body-secondary"
            >Status: {props.currentState.currentProgressMsg}
            </Box>
            <Box></Box>
            <LoadingBar variant="gen-ai" />
            </ColumnLayout>
            <Box color='text-status-info'>Time taken:Most reports take 5m+. Month wise reports take ~10m+.</Box>
            {/* <ExtendingPanel label="Prompt examples">
                <SpaceBetween direction='vertical' size='xxs'>
                    <CopyToClipboard copySuccessText="prompt copied" textToCopy="what are the trends for oct 24?" variant='inline'/>
                    <CopyToClipboard copySuccessText="prompt copied" textToCopy="what are the cloudflare compete situations?" variant='inline'/>
                    <CopyToClipboard copySuccessText="prompt copied" textToCopy="top trends and issues by service over last 3 months" variant='inline'/>
                </SpaceBetween>
            
            </ExtendingPanel> */}
            <Box color='text-status-warning'>Note: This tool is currently experimental and the output should be thorougly vetted and corrected for errors</Box>  
            <ExtendingPanel label="Extend it">
                    <ColumnLayout columns={2} variant='text-grid' className='extend_it'>
                        <Box>Questions/ideas on this tool?</Box>
                        <SpaceBetween direction='vertical'>
                            <Box variant='strong'>
                                Jaiganesh Girinathan,
                                ganeshji@amazon.com
                            </Box>
                        </SpaceBetween>
                    </ColumnLayout>
                </ExtendingPanel>
            </SpaceBetween>
        </React.Fragment>
    );
};

const Message1 = ({message,key}) => {
    return(
    message.user === 'bot'?
        <SpaceBetween direction='horizontal' size='xs'>
            {1==0 && <CodeView key={key} variant='awsui-gen-ai-label' content={message.msg} actions={
                (message.type === 'specs' || message.type === 'template')?
                    <CopyToClipboard
                    copyButtonAriaLabel="Copy code"
                    copyErrorText="Code failed to copy"
                    copySuccessText="Code copied"
                    textToCopy={message.msg}/>:''}/>}
            <Box variant='samp' >
            <Avatar key={key} 
            ariaLabel="Avatar of Edge Configuration AI assistant"
            color="gen-ai"
            iconName="gen-ai"
            tooltipText="Edge Configuration Assistant"/>
 
                {message.msg}
            </Box>
        </SpaceBetween>:
        <SpaceBetween direction='horizontal' size='xs'>
            <Avatar key={key}
                ariaLabel="You"
                tooltipText="You"/>
                <Box key={key}>{message.msg}</Box>
        </SpaceBetween>
    )
};

const CAvatar = ({message,key}) => {
    if(message.user === 'bot'){
        if(message.type === 'question'){
            return(
                <SpaceBetween direction='vertical' size='xxxs'>
                    <Icon name="gen-ai" alt='Edge Configuration AI assistant'/>                
                </SpaceBetween>
            );
        }
        return (
            <Icon name="gen-ai" alt='Edge Configuration AI assistant'/>
        );
    }
    else{
        return (<Icon name="user-profile" alt='You'/>);
    }
}

const CMsg = ({message,key}) => {
    // const [displayedContent, setDisplayedContent] = useState("");
    // const [index, setIndex] = useState(0);
    // useEffect(() => {
    //     /*Create a new setInterval and store its id*/
    //     if(!message['viewed']){
    //     const animKey = setInterval(() => {
    //       setIndex((index) => {
    //         /*This setState function will set the index
    //         to index+1 if there is more content otherwise
    //         it will destory this animation*/
          
    //         if (index >= message['msg'].length - 1) {
    //           clearInterval(animKey);
    //           message['viewed'] = true;
    //           return index;
    //         }
    //         return index + 1;
    //       });
    //     }, 20);
    //     }
    //   }, []);
    
    //   useEffect(() => {
    //     if(!message['viewed']){
    //         setDisplayedContent((displayedContent)=>displayedContent + message['msg'][index]);
    //     }
    //     else{
    //         setDisplayedContent(message['msg']);
    //     }
    //   }, [index])

    return (
    message.user === 'bot'?
    <div className="chat-msg">
        <CodeView key={key} content={<Markdown remarkPlugins={[remarkGfm]}>{message.msg}</Markdown>} actions={<CopyToClipboard copySuccessText="copied" textToCopy={message.msg} variant='icon'/>}/>
    </div>
    :<Box key={key} variant='samp'>{message.msg}</Box>);
}

{/* <CodeView key={key} content={message.msg}
actions={message.type === 'question1'?<Icon name="flag" alt="Question" variant='subtle'/>:""}/> */}

const Message = ({message,key}) => {

    return(
        <div
            style={{
              display: "flex",
              flexDirection:'inherit',
              alignItems: "top",
              gap:5,
            }}
          >
            <CAvatar key={key} message={message}/>

            <CMsg key={key} message={message}/>
          </div>
    );
}

const ChatPanel = props => {

    return (
        <Container>
        <div id="messages" style={{ height: 400 + 'px', overflow: 'auto', padding: 0 + 'px' }} onScroll={props.handleScroll}>
            {/* <Messages messages={props.messages}/> */}
            <SpaceBetween direction='vertical' size='xs'>
                {props.messages?.map((message,index) => {
                    return <Message message={message} key={index}/>
                })}
            </SpaceBetween>
        </div>
    </Container>
    );
}

const GChatPanel = props => {
    return (
        <Container 
        // footer={
        //     <Grid gridDefinition={[
        //         { colspan: 9 }, { colspan: 3 },
        //     ]}>
        //     <div>
        //         <Textarea onChange={props.onInputChange.bind(this, 'gCurrentAnswer')} 
        //         value={props.currentState.gCurrentAnswer} rows={4} autoFocus 
        //         placeholder="Answer here"/></div>
        //     <div>
        //         <SpaceBetween direction='vertical'>
        //             <SpaceBetween direction='horizontal' size='m'>
        //                 <Button variant="primary" onClick={props.handleGenerate}>Generate</Button>
        //                 <Button variant="normal" onClick={props.handleReset}>Reset</Button>
        //             </SpaceBetween>
        //         {/* <ProgressBar
        //             value={props.currentState.currentProgress}
        //             additionalInfo={props.currentState.currentProgressMsg}
        //             /> */}
        //         </SpaceBetween>
        //     </div>
        //     </Grid>}
        >
        <div id="gmessages" style={{ height: 400 + 'px', overflow: 'auto', padding: 0 + 'px' }} onScroll={props.handleScroll}>
            <SpaceBetween direction='vertical' size='xxs'>
                {props.messages?.map((message,index) => {
                    return <Message message={message} key={index}/>
                })}
            </SpaceBetween>
        </div>
    </Container>
    );
}

const VChatPanel = props => {
    return (
        <Container 
        // footer={
        //     <Grid gridDefinition={[
        //         { colspan: 9 }, { colspan: 3 },
        //     ]}>
        //     <div>
        //         <Textarea onChange={props.onInputChange.bind(this, 'gCurrentAnswer')} 
        //         value={props.currentState.gCurrentAnswer} rows={4} autoFocus 
        //         placeholder="Answer here"/></div>
        //     <div>
        //         <SpaceBetween direction='vertical'>
        //             <SpaceBetween direction='horizontal' size='m'>
        //                 <Button variant="primary" onClick={props.handleGenerate}>Generate</Button>
        //                 <Button variant="normal" onClick={props.handleReset}>Reset</Button>
        //             </SpaceBetween>
        //         {/* <ProgressBar
        //             value={props.currentState.currentProgress}
        //             additionalInfo={props.currentState.currentProgressMsg}
        //             /> */}
        //         </SpaceBetween>
        //     </div>
        //     </Grid>}
        >
        <div id="vmessages" style={{ height: 400 + 'px', overflow: 'auto', padding: 0 + 'px' }} onScroll={props.handleScroll}>
            <SpaceBetween direction='vertical' size='xxs'>
                {props.messages?.map((message,index) => {
                    return <Message message={message} key={index}/>
                })}
            </SpaceBetween>
        </div>
    </Container>
    );
}

const LogPanel = props => {
    return (
        <Container>
        <SpaceBetween size='s'>
        {props.currentState.logs?.map((log,index) => {
            return(
            <TextContent key={index}>{log.log}</TextContent>
            );
        })}
        </SpaceBetween>
        </Container>
    );
}

const TablePanel = props => {

    return (
        <Table key={props.label}
            columnDefinitions={[
                {
                    id: "title",
                    // header: <TableInfo label="Title" index="1" replaceToolsContent={props.replaceToolsContent} />,
                    // cell: item => item.metadata['title'] || "-",
                        cell: item => <ExpandableSection headerText={item.metadata['title']}>
                            <Box variant='code'>{item.page_content}</Box>
                            </ExpandableSection>
                },
                {
                    id: "publish_date",
                    header: "Publish Date",
                    // header: <TableInfo label="Title" index="1" replaceToolsContent={props.replaceToolsContent} />,
                    // cell: item => item.metadata['title'] || "-",
                        cell: item => <Box variant='code'>{item.metadata['publish_date']}</Box>
                },
                {
                    id: "actilinkons",
                    header: "Link",
                    cell: item => (
                      <Link external href={item.metadata['url']}>
                        View
                      </Link>
                    )
                }
            ]}
            variant="container"
            items={props.results}
            loadingText="Loading data.."
            sortingDisabled
            header=<TableInfo label={props.label} replaceToolsContent={props.replaceToolsContent} index={props.index} />
        />
    );
};

const LearnSomethingPanel = props => {

    return (
        <ExtendingPanel label="Learn Something new">
            <Table key={props.label}
                columnDefinitions={[
                    {
                        id: "title",
                        // header: <TableInfo label="Title" index="1" replaceToolsContent={props.replaceToolsContent} />,
                        // cell: item => item.metadata['title'] || "-",
                        cell: item => <Link variant='info'>{item.q}</Link>,    
                    },
                    // {
                    //     id: "actilinkons",
                    //     header: "Link",
                    //     cell: item => (
                    //       <Link external href={item.metadata['url']}>
                    //         View
                    //       </Link>
                    //     )
                    // }
                ]}
                variant="container"
                items={props.results}
                loadingText="Loading data.."
                sortingDisabled
                wrapLines
                header=<TableInfo label={props.label} replaceToolsContent={props.replaceToolsContent} index={props.index} />
            />
            </ExtendingPanel>
    );
};

const RelatedQuestionsPanel = props => {

    return (
        <ExtendingPanel label="Related questions">
            <form onSubmit={props.handleClick}>

            <Table key={props.label}
                columnDefinitions={[
                    {
                        id: "title",
                        // header: <TableInfo label="Title" index="1" replaceToolsContent={props.replaceToolsContent} />,
                        // cell: item => item.metadata['title'] || "-",
                        cell: item => <CopyToClipboard
                            copyButtonText="Copy"
                            copyErrorText="Copy failed"
                            copySuccessText="Question copied. Paste it in above text box."
                            textToCopy={item.page_content}
                            variant="inline"
                        />
                    },
                    // {
                    //     id: "actilinkons",
                    //     header: "Link",
                    //     cell: item => (
                    //       <Link external href={item.metadata['url']}>
                    //         View
                    //       </Link>
                    //     )
                    // }
                ]}
                variant="container"
                items={props.results}
                loadingText="Loading data.."
                sortingDisabled
                wrapLines
                // header=<TableInfo label={props.label} replaceToolsContent={props.replaceToolsContent} index={props.index}
            />
            </form>
        </ExtendingPanel>
    );
};

const Loading = props => {
    if (props.results.length == 0)
        return "loading"
    else
        return ""
}
const TableInfo = props => {
    return (
        <Header variant="h5" info={
            <Link variant="info" onFollow={() => props.replaceToolsContent(props.index)}>
                Info
            </Link>}>{props.label}</Header>
    );
};

// List of Help (right) panel content, changes depending on which 'info' link the user clicks on.
const Tools = [
    <HelpPanel
        header={<h3>{demoModule}</h3>}
    >
        <p>
        Find content across blogs, workshops, Immersion Days, YouTube, Cloud@Edge
        </p>
    </HelpPanel>,
    <HelpPanel header={<h2>Question</h2>}>
        Find content across blogs, workshops, Immersion Days, YouTube, Cloud@Edge
    </HelpPanel>,
    <HelpPanel header={<h2>Language</h2>}>
        Enter your question in selected local language selected and it will be translated 
        to 'English' and semantically searched. Note, the results are show in 'English'.
    </HelpPanel>,

];