import React from 'react';
import DataProvider from '../../resources/data-provider';
import ServiceNavigation from '../ServiceNavigation.jsx';
import SynopsisPanelV2 from '../SynopsisPanelV2';
import ExtendingPanel from '../ExtendingPanel.jsx';
import BrowserCheck from '../BrowserCheck';
import TopPanel from '../TopPanel';
import architectureImg from './images/cf_origin_failover.png';

import {
    ORIGINFAILOVER_ENDPOINT,
} from '../../resources/prod-env.jsx';
import {
    AppLayout,
    Button,
    ColumnLayout,
    Form,
    FormField,
    Select,
    SpaceBetween,
    Container,
    Link,
    HelpPanel,
    Box,
    Icon,
    Alert,
    Checkbox,
    ProgressBar,
    Badge,
    Header,
    Grid
} from '@cloudscape-design/components';

import { sendAnalytics, sendError } from '../../resources/rum-provider.ts';
const demoModule = "OriginFailover";

// Class CreateForm is a skeleton of a Single page create form using AWS-UI React components.
export default class OriginFailover extends React.Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleAlert = this.handleAlert.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.dataProvider = new DataProvider();
        this.state = {
            toolsIndex: 0, toolsOpen: false, alert: false,
            httpMethod: "", noOfAttempts: "", currentCounter: 0, 
            primary: { checked: false }, secondary: { checked: false },
            reset: false,
            responses: [],
            avgLatencyPrimary: "",
            avgLatencyFailover: ""
        };
    }

    onInputChange(name, e) {
        e.preventDefault();
        console.log("In onInputChange %s %j", name, e);
        if (name === "noOfAttempts") {
            this.setState({ [name]: e.detail.selectedOption });
            return;
        }
        else if (name === "httpMethod") {
            this.setState({ [name]: e.detail.selectedOption });
            return;
        }
        else if (name === 'primary') {
            this.setState({ [name]: e.detail });
        }
        else if (name === 'secondary') {
            this.setState({ [name]: e.detail });
        }
    }

    handleClick(e) {
        e.preventDefault();
        let responseArr = [];
        let latencyArrPrimary = [];
        let latencyArrFailover = [];
        if(this.state.reset) {
            this.setState({ reset: false, currentCounter: 0 });
            return;
        } 

        sendAnalytics({ demo: demoModule });

        let url = `${ORIGINFAILOVER_ENDPOINT}/failover/`;
        
        let attempts = 0;
        this.setState({
            reset: true
        });

        let params = {action: "rbr"};
        let requestHeaders = {};

        if (!this.state.noOfAttempts) {
            this.setState({ alert: true, reset: false });
            return;
        }
        else {
            this.handleAlert();
        }

        let intervalCFImages = setInterval(() => {
            if (this.state.httpMethod.value == 'POST') {
                this.setState({ alert: true, reset: false });
                return;
            }

            if (attempts < this.state.noOfAttempts.value && this.state.reset) {

                attempts++;
                if (this.state.primary?.checked) {
                    params.failPrimary = true;
                } else {
                    delete params['failPrimary'];
                }
                if (this.state.secondary?.checked) {
                    params.failSecondary = true;
                } else {
                    delete params['failSecondary'];
                }

                if(this.state.httpMethod.value == 'GET') {
                    this.dataProvider.getWAFCommonData(params, requestHeaders, url, response => {
                        responseArr.push({
                            reqUrl: response.url,
                            latency: response.time,
                            respStatus: response.status,
                            respBody: response.response.error ? response.response.error : JSON.stringify(response.response),
                            time: new Date()
                            });
                        if(response.response.origin == 'primary') {
                            latencyArrPrimary.push(response.time);
                        } else  {
                            latencyArrFailover.push(response.time);
                        }
                        let sortedData= responseArr.sort((function (a, b) { 
                            return new Date(b.time) - new Date(a.time) 
                            }));
                        this.setState({ responses: sortedData, avgLatencyPrimary: averageLatency(latencyArrPrimary), avgLatencyFailover: averageLatency(latencyArrFailover) });
                        console.log("Response :%j", response);
                    }, this.dataProvider);
                }

                this.setState({ currentCounter: attempts });
            }
            else {
                this.setState({reset: false});
                clearInterval(intervalCFImages);
            }
        }, 1000);
    }

    handleAlert() {
        // console.log("In handleAlert");
        this.setState({ alert: false });
    }

    componentDidCatch(error, info) {
        console.log(error);
        // cwr('recordError', error);
        sendError({ demo: demoModule, error: error });
    };

    componentDidMount() {
        // this.dataProvider.getData('images', response => this.setState({ images: response }));
    }

    render() {
        return (
            <React.Fragment>
                <TopPanel label={demoModule} />
                <AppLayout
                    navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
                    content={
                        <Content
                            // Changes the Help panel content when the user clicks an 'info' link
                            replaceToolsContent={index => this.setState({ toolsIndex: index, toolsOpen: true })}
                            handleClick={this.handleClick}
                            handleAlert={this.handleAlert}
                            onInputChange={this.onInputChange}
                            currentState={this.state}
                        />
                    }
                    contentType="default"
                    tools={Tools[this.state.toolsIndex]}
                    onToolsChange={({ detail }) => this.setState({ toolsOpen: detail.open })}
                    toolsOpen={this.state.toolsOpen}
                    navigationOpen={this.state?.navigationState}
                    onNavigationChange={e => this.setState({ navigationState: e.detail.open })}
                />
            </React.Fragment>
        );
    }
};

const averageLatency = function(array) {
    if(array.length > 0) {
        let avg = array.reduce((lat1, lat2) => lat1 + lat2) / array.length;
        return avg.toFixed(2);
    } else {
        return 'N/A';
    }
}  

const FailoverPanel = props => {
    return (
        <SpaceBetween direction='vertical' size='l'>
            <SpaceBetween direction='horizontal' size='l'>
                <FormField label="HTTP Method" info={
                    <Link variant="info" onFollow={() => props.replaceToolsContent(1)}>
                        Info
                    </Link>
                }>
                    <Select
                        selectedOption={props.currentState?.httpMethod}
                        onChange={props.onInputChange.bind(this, 'httpMethod')}
                        options={[
                            { label: "GET/HEAD", value: "GET" },
                            { label: "POST/PUT/DELETE", value: "POST" },
                        ]}
                        selectedAriaLabel="Selected"
                    />
                </FormField>
                <FormField label="# Attempts" info={
                    <Link variant="info" onFollow={() => props.replaceToolsContent(2)}>
                        Info
                    </Link>
                }>
                    <Select
                        selectedOption={props.currentState?.noOfAttempts}
                        onChange={props.onInputChange.bind(this, 'noOfAttempts')}
                        options={[
                            { label: "5", value: "5" },
                            { label: "50", value: "50" },
                            { label: "100", value: "100" },
                            { label: "150", value: "150" },
                            { label: "200", value: "200" },
                        ]}
                        selectedAriaLabel="Selected"
                    />
                </FormField>
                <FormField label="Primary Unhealthy" info={
                    <Link variant="info" onFollow={() => props.replaceToolsContent(3)}>
                        Info
                    </Link>
                }>
                    <Checkbox
                        onChange={props.onInputChange.bind(this, 'primary')}
                        checked={props.currentState?.primary?.checked}
                    />
                </FormField>
                <FormField label="Secondary Unhealthy" info={
                    <Link variant="info" onFollow={() => props.replaceToolsContent(4)}>
                        Info
                    </Link>
                }>
                    <Checkbox
                        onChange={props.onInputChange.bind(this, 'secondary')}
                        checked={props.currentState?.secondary?.checked}
                    />
                </FormField>
            </SpaceBetween>
            <SpaceBetween direction='horizontal' size='l'>
                <SpaceBetween direction='vertical' size='xxs'>
                    <FormField label="CloudFront Configurations" info={
                        <Link variant="info" onFollow={() => props.replaceToolsContent(5)}>
                            Info
                        </Link>
                    }></FormField>
                    <Box variant='p'>Caching: Disabled</Box>
                    <Box variant='p'>Connection Timeout: 10s</Box>
                    <Box variant='p'>Status code configured for failover: 500</Box>
                </SpaceBetween>
            </SpaceBetween>
        </SpaceBetween>
    );
};

const ResultPanel = props => {
    if (props.currentState?.currentCounter) {
        return (
            <SpaceBetween direction='vertical' size='l'>
                <ProgressBar
                    value={props.currentState.currentCounter * 100 / props.currentState.noOfAttempts.value}
                    variant={'standalone'}
                    description={`Attempts :${props.currentState.currentCounter}`}
                // label="Progress bar label"
                />
                <SpaceBetween direction='horizontal' size='xxs'>
                    <Box variant='p' fontWeight="bold">Average Latency (Primary Origin): </Box>
                    <Box variant='p'>{props.currentState.avgLatencyPrimary} ms</Box>
                </SpaceBetween>
                <SpaceBetween direction='horizontal' size='xxs'>
                    <Box variant='p' fontWeight="bold">Average Latency (After Failover): </Box>
                    <Box variant='p'>{props.currentState.avgLatencyFailover} ms</Box>
                </SpaceBetween>
                <TestResultsPanel results={props.currentState.responses} />
            </SpaceBetween>
        )
    } else {
        return ("");
    }
};

const TestResultsPanel = props => {
    if (props.results.length > 0) {
      return (
        <Container header={
          <Header variant='h3'
          >Showing {props.results.length} results </Header >}>
          <div style={{
            height: 500 + 'px', overflow: 'auto'
          }
          }>
            <ColumnLayout columns={1} borders='horizontal'>
  
              {props.results?.map((result, index) =>
                <Grid key={index}
                  disableGutters
                  gridDefinition={[
                    { colspan: { default: 3, xxs: 3 } },
                    { colspan: { default: 9, xxs: 9 } }
                  ]}              >
                  <SpaceBetween direction='vertical' size='xxs'>
                    <SpaceBetween direction='horizontal' size='xxs'>
                        <Box variant='p' fontWeight="bold">Time: </Box>
                        <Box variant='p'>{result.time.toLocaleString()}</Box>
                    </SpaceBetween>
                    <SpaceBetween direction='horizontal' size='xxs'>
                        <Box variant='p' fontWeight="bold">Latency: </Box>
                        <Box variant='p'>{result.latency} ms</Box>
                    </SpaceBetween>
                  </SpaceBetween>
                  <SpaceBetween direction='vertical' size='xxs'>
                    <Badge color={result.respStatus === 200 ? "green" : "red"}>
                      Status: {result.respStatus}
                    </Badge>
                    <Box variant='strong'>
                      <React.Fragment>
                        {result.respBody}
                      </React.Fragment>
                    </Box>
                  </SpaceBetween>
                </Grid>
              )}
            </ColumnLayout>
          </div >
        </Container >
      )
    } else {
      return ("");
    }
  };

const Content = props => {

    return (
        <React.Fragment>
            <BrowserCheck />
            <SpaceBetween direction='vertical' size='l'>
                <SynopsisPanelV2 label="Synopsis" architectureImgs={[architectureImg]}>
                
                </SynopsisPanelV2>
                <Container>
                    <form onSubmit={props.handleClick}>
                        <Form
                            actions={
                                <SpaceBetween direction="vertical" size="xs">
                                    {props.currentState.alert && <Alert type='error'
                                        // visible={props.currentState.alert}
                                        onDismiss={() => props.handleAlert()}
                                        // dismissAriaLabel={<Box variant='strong'>Close alert</Box>}
                                        dismissible
                                    // header={<Box variant='p'>Select AWS Region</Box>}
                                    >
                                        {props.currentState.httpMethod.value == 'POST' && <Box variant='small'>POST/PUT/DELETE methods are not supported by Cloudfront Origin Failover</Box>}
                                        {props.currentState.httpMethod.value == 'GET' && <Box variant='small'>Enter required inputs and hit 'Fetch'</Box>}
                                    </Alert>}
                                    <Button variant="primary">{props.currentState.reset ? 'Reset' : 'Fetch'}</Button>
                                </SpaceBetween>
                            }
                        >

                            <FailoverPanel currentState={props.currentState} onInputChange={props.onInputChange} replaceToolsContent={props.replaceToolsContent} />
                            
                        </Form>
                    </form>
                </Container>
                <Container header=<Box variant='h4'>Result</Box>>
                    <ResultPanel currentState={props.currentState} />
                </Container>
                <ExtendingPanel label="Extend it">
                    <ColumnLayout columns={2} variant='text-grid' className='extend_it'>
                        <Box>Questions/ideas on the demo?</Box>
                        <SpaceBetween direction='vertical'>
                            <Box variant='strong'>
                                Kiruthi Jayachandran,
                                kiruthij@amazon.com
                            </Box>
                        </SpaceBetween>
                    </ColumnLayout>
                </ExtendingPanel>
            </SpaceBetween>
        </React.Fragment >
    );
};

// List of Help (right) panel content, changes depending on which 'info' link the user clicks on.
const Tools = [
    <HelpPanel
        header={<h3>CloudFront Origin Failover</h3>}
        footer={
            <div>
                <h3>
                    Learn more <Icon name="external" />
                </h3>
                <ul>
                    <li>
                        <a href="https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/high_availability_origin_failover.html" target="_blank">
                            Optimize high availability with CloudFront origin failover
                        </a>
                    </li>
                </ul>
            </div>
        }
        index="0">
        <p>
        You can set up CloudFront with origin failover for scenarios that require high availability.
        </p>
    </HelpPanel >,
    <HelpPanel header={<h2>HTTP Method</h2>} index="1">
        Choose the HTTP method used by requests.
        CloudFront fails over to the secondary origin only when the HTTP method of the viewer request is GET, HEAD, or OPTIONS. CloudFront does not fail over when the viewer sends a different HTTP method (for example POST, PUT, and so on).
    </HelpPanel>,
    <HelpPanel header={<h2># Attempts</h2>} index="2">
        No. of requests made by your browser
    </HelpPanel>,
    <HelpPanel header={<h2>Primary Unhealthy</h2>} index="3">
        Mark primary origin as unhealthy
    </HelpPanel>,
    <HelpPanel header={<h2>Secondary Unhealthy</h2>} index="4">
        Mark secondary origin as unhealthy
    </HelpPanel>,
    <HelpPanel header={<h2>CloudFront Configurations</h2>} index="5">
        Current configurations of CloudFront
    </HelpPanel>,
];